<template>
  <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasRightspeed_blocks"
      aria-labelledby="offcanvasRightLabelspeed_blocks">
    <div class="offcanvas-header">
      <h2 class="offcanvas-title text-center" id="offcanvasRightLabel">
        {{ langs["title_speed_blocks"] }}
      </h2>
      <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"></button>
    </div>
    <div class="offcanvas-body text-center">
      <img src="../../assets/explanation/speed_blocks.gif" alt="an example showing what is speed_blocks" width="300px">
      <br/>
      {{ langs["speed_blocks_explain"] }}
      <br/>
      <br/>

      <p class="fs-4 text-center">
        {{ langs["speed_blocks_btn_describe"] }}
      </p>
      <div class="buttons-container">
        <button
            v-for="n in all_value"
            :key="n"
            @click="changeButtonContrast(n)"
            :class="{
						'btn-primary': value.speed_blocks === parseRawValue(n),
						'btn-secondary': value.speed_blocks !== parseRawValue(n),
					}"
            class="btn m-1">
          {{ n }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    langs: Object,
    value: Object
  },
  components: {},
  data() {
    return {

      all_value: [],
    }
  },
  methods: {
    parseRawValue(val) {
      console.log
      return parseFloat(val)
    },
    changeButtonContrast(n) {
      this.$emit('update:value', {...this.value, speed_blocks: this.parseRawValue(n)})
    },
    initializeAllValue() {
      this.all_value = []
      for (let i = 0.1; i <= 1.9; i += 0.1) {
        this.all_value.push(`${Math.ceil(i * 10) / 10} px`)
      }
    }
  },
  created() {
    this.initializeAllValue()
  }
}
</script>
