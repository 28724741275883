<template>
  <div id="app">
    <p class="main_title">
      {{ langs["main_title"] }}
    </p>
    <div class="all-panel mb-5">
      <ParameterPanel
          v-for="(value, name_key) in user_settings"
          :key="name_key"
          :value="value"
          :user_settings="user_settings"
          :langs="langs"
          :name_key="name_key"/>
      <div class="panel mt-5">
        <div @click="to_factory_settings()" class="value">
          <div class="emoji-icon">
            <img
                src="./assets/icons/factory_settings.svg"
                alt="factory_settings"/>
          </div>
          <p>{{ langs["btn_factory_settings"] }}</p>
        </div>
      </div>
    </div>
  </div>


  <LabelColour :value="user_settings" :langs="langs" @update:value="user_settings = $event"/>
  <LabelErrorCorrection
      :value="user_settings"
      :langs="langs"
      @update:value="user_settings = $event"/>
  <LabelBoxSize :value="user_settings" :langs="langs" @update:value="user_settings = $event"/>
  <LabelBorder :value="user_settings" :langs="langs" @update:value="user_settings = $event"/>
  <LabelСrop
      :value="user_settings"
      :langs="langs"
      @update:value="user_settings = $event"/>
  <LabelVersion :value="user_settings" :langs="langs" @update:value="user_settings = $event"/>
  <LabelContrast
      :value="user_settings"
      :langs="langs"
      @update:value="user_settings = $event"/>
  <LabelBrightness
      :langs="langs"
      :value="user_settings"
      @update:value="user_settings = $event"/>
  <LabelSpeedBlocks
      :langs="langs"
      :value="user_settings"
      @update:value="user_settings = $event"/>
</template>
<script>
import ParameterPanel from './components/parameter_panel.vue'
import LabelColour from './components/label/colourL.vue'
import LabelErrorCorrection from './components/label/error_correctionL.vue'
import LabelBoxSize from './components/label/box_sizeL.vue'
import LabelBorder from './components/label/borderL.vue'
import LabelСrop from './components/label/cropL.vue'
import LabelVersion from './components/label/versionL.vue'
import LabelContrast from './components/label/contrastL.vue'
import LabelBrightness from './components/label/brightnessL.vue'
import LabelSpeedBlocks from './components/label/speed_blocksL.vue'

import {postEvent} from '@tma.js/sdk';
import {ClosingBehavior} from '@tma.js/sdk';
import axios from 'axios';
import {loadUserInfo} from "@/components/lang";

const closingBehaviour = new ClosingBehavior(false, postEvent);
export default {
  components: {
    ParameterPanel,
    LabelColour,
    LabelErrorCorrection,
    LabelBoxSize,
    LabelBorder,
    LabelСrop,
    LabelVersion,
    LabelContrast,
    LabelBrightness,
    LabelSpeedBlocks
  },
  name: 'App',
  data() {
    return {
      user_settings: Object,
      old_user_settings: Object,
      langs: this.langs,
    }
  },
  watch: {
    user_settings(user_settings) {
      if (JSON.stringify(user_settings) === JSON.stringify(this.old_user_settings)) {
        this.TMA.MainButton.hide()
        closingBehaviour.disableConfirmation();
      } else {
        this.TMA.MainButton.show()
        this.TMA.HapticFeedback.impactOccurred('rigid')
        this.TMA.MainButton.setText(this.langs["btn_save_change"])
        closingBehaviour.enableConfirmation();
      }
    }
  },
  methods: {
    to_factory_settings() {
      this.user_settings = {
        colour: {
          color_bg: [255, 255, 255],
          color_pixels: [1, 1, 1]
        },
        box_size: 10,
        border: 4,
        error_correction: 'H',
        crop: true,
        version: 8,
        contrast: 1.0,
        brightness: 1.0,
        speed_blocks: 0.7,
      };
    },
    mainButtonClicked() {
      this.TMA.HapticFeedback.impactOccurred('rigid');
      this.TMA.sendData(JSON.stringify(this.user_settings))
      this.TMA.close()
    },
    async fetchUserData(userId) {
      try {
        const response = await axios.get(`https://bot-api.qr-code-bot.ru/users/${userId}`);
        console.log(response.data)
      } catch (error) {
        console.error("Error fetching user data:", error);
      }

    }
  },
  async created() {
    this.TMA.ready();
    this.TMA.expand();
    this.TMA.onEvent('mainButtonClicked', this.mainButtonClicked)
    // await this.sleep(1000);
    // Я оставлю тут костыль
    const userInfo = await loadUserInfo(7036862597);
    this.langs = userInfo.lang;
    this.user_settings = userInfo.old_user_settings;
    this.old_user_settings = {...this.user_settings};
    this.have_premium = userInfo.have_premium;

  }
}
</script>

<style scoped>
.main_title {
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
  font-size: 20px;
}

.all-panel {
  display: grid;
  place-items: center;
}

.panel {
  display: flex;
  text-align: center;
  margin-top: 7px;
  width: 365px;
  height: 75px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.emoji-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50%;
  margin: 7px;
  font-size: 32px;
}

.value p {
  font-size: 18px;
  margin: 0;
}

.value {
  justify-content: center;
  width: 355px;
  display: flex;
  align-items: center;
}
</style>
