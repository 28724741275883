<template>
  <button
      class="panel"
      type="button"
      data-bs-toggle="offcanvas"
      :data-bs-target="'#offcanvasRight' + name_key"
      :aria-controls="'offcanvasRight' + name_key">
    <div class="emoji-icon">
      <div class="emoji">
        <div v-if="name_key == 'colour'">
          <img src="../assets/icons/colour.svg" style="padding-bottom: 4px" alt="colour"/>
        </div>
        <div v-if="name_key == 'error_correction'">
          <img src="../assets/icons/error_correction.svg" style="padding-bottom: 4px" alt="error_correction"/>
        </div>
        <div style="font-size: 26px" class="emoji_reduction" v-if="name_key == 'box_size'">
          <img src="../assets/icons/box_size.svg" alt="border"/>
        </div>
        <div class="emoji_reduction" v-if="name_key == 'border'">
          <img src="../assets/icons/border.svg" style="padding-bottom: 4px" alt="border"/>
        </div>
        <div class="emoji_reduction" v-if="name_key == 'crop'">
          <img src="../assets/icons/crop.svg" style="padding-bottom: 4px" alt="crop"/>
        </div>
        <div class="emoji_reduction" v-if="name_key == 'version'">
          <img src="../assets/icons/version.svg" style="padding-bottom: 4px" alt="version"/>
        </div>
        <div class="emoji_reduction" v-if="name_key == 'contrast'">
          <img src="../assets/icons/contrast.svg" style="padding-bottom: 4px" alt="contrast"/>
        </div>
        <div class="emoji_reduction m-1" v-if="name_key == 'brightness'">
          <img src="../assets/icons/brightness.svg" style="padding-bottom: 8px" alt="brightness"/>
        </div>
        <div class="emoji_reduction" v-if="name_key == 'speed_blocks'">
          <img src="../assets/icons/speed_blocks.svg" style="padding-bottom: 4px" alt="speed_blocks"/>
        </div>
      </div>
    </div>

    <div class="key-title text-start">
      <div v-if="name_key == 'colour'">{{ langs["title_colour"] }}</div>
      <div v-if="name_key == 'error_correction'">
        {{langs["title_error_correction"] }}
      </div>
      <div v-if="name_key == 'box_size'">{{ langs["title_box_size"] }}</div>
      <div v-if="name_key == 'border'">{{ langs["title_border"] }}</div>
      <div v-if="name_key == 'crop'" >{{ langs["title_crop"] }}</div>
      <div v-if="name_key == 'version'">{{ langs["title_version"] }}</div>
      <div v-if="name_key == 'contrast'">{{ langs["title_contrast"] }}</div>
      <div v-if="name_key == 'brightness'">{{ langs["title_brightness"] }}</div>
      <div v-if="name_key == 'speed_blocks'">{{
          langs["title_speed_blocks"]
        }}
      </div>
    </div>

    <div class="value">
      <div class="value-text">
        <div v-if="name_key == 'colour'">
          <ColorQRCode :colour="value" :size="'40px'"></ColorQRCode>
        </div>
        <div v-if="(name_key == 'error_correction') & (value == 'H')">30%</div>
        <div v-if="(name_key == 'error_correction') & (value == 'Q')">25%</div>
        <div v-if="(name_key == 'error_correction') & (value == 'M')">15%</div>
        <div v-if="(name_key == 'error_correction') & (value == 'L')">7%</div>
        <div v-if="name_key == 'box_size'">{{ value }}px</div>
        <div class="m-1" v-if="name_key == 'crop'">
          {{
            value ? langs["yes"] : langs["no"]
          }}
        </div>
        <div class="m-2" v-if="name_key == 'border'">{{ value }}</div>
        <div class="m-2" v-if="name_key == 'version'">{{ value }}</div>
        <div class="m-2" v-if="name_key == 'contrast'">{{ value }}</div>
        <div class="m-2" v-if="name_key == 'brightness'">{{ value }}</div>
        <div class="m-2" v-if="name_key == 'speed_blocks'">{{ value }}</div>
      </div>
      <i class="bi bi-arrow-bar-right"></i>
    </div>
  </button>
</template>

<script>
import ColorQRCode from './color_QR_code.vue'

export default {
  components: {
    ColorQRCode,
  },
  props: {
    name_key: String,
    user_settings: Object,
    langs: Object,
    value: [String, Number, Object, Boolean],
  },
}
</script>
