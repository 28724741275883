<template>
	<div
		class="offcanvas offcanvas-end"
		tabindex="-1"
		id="offcanvasRightbox_size"
		aria-labelledby="offcanvasRightLabelbox_size">
		<div class="offcanvas-header">
			<h5 class="offcanvas-title" id="offcanvasRightLabel">
				{{ langs["box_size_main_title"] }}
			</h5>
			<button
				type="button"
				class="btn-close"
				data-bs-dismiss="offcanvas"
				aria-label="Close"></button>
		</div>
		<div class="info-blok mb-5">
			<div class="image-container">
				<div class="offcanvas-body">
					<img
						src="../../assets/explanation/box_size.png"
						alt="an example showing what is box_size" />
				</div>
			</div>
			<div class='text'>
        {{langs["box_size_explain"]}}
			</div>
			<br />
			<br />
			<p class="fs-4 text-center"> {{ langs["box_size_btn_describe"] }} </p>
			<div class="buttons-container">
				<button
					v-for="n in all_value"
					:key="n"
					@click="changeButtonColor(parseRawValue(n))"
					:class="{
						'btn-primary': value.box_size === parseRawValue(n),
						'btn-secondary': value.box_size !== parseRawValue(n),
					}"
					class="btn m-1">
					{{ n }}
				</button>
			</div>

			<div class="input-group mt-3 p-2">
				<input
					type="text"
					class="form-control"
					:placeholder="placeholder"
					v-model="userInput"
					@input="validateInput" />
				<button
					class="btn btn-outline-secondary"
					type="button"
					@click="applyInputValue">
          {{langs["btn_save"]}}
				</button>
			</div>
			<div v-if="inputError" class="alert alert-danger mt-2">
				{{ inputError }}
			</div>
			<div class="scroll-through"></div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    langs: Object,
    value: Object
  },
	data() {
		return {
			all_value: [],
			errorCorrectionLevel: 1,
			showNotification: false,
			fadeIn: false,
			fadeOut: false,
			activeButton: null,
			userInput: `${this.value.box_size}px`,
			placeholder: `${this.value.box_size} px`,
			max_value: 999,
		}
	},
	methods: {
		changeButtonColor(n) {
			this.$emit('update:value', { ...this.value, box_size: n })
		},
		parseRawValue(val) {
			return parseInt(val.split(' ')[0])
		},
		initializeAllValue() {
			this.all_value = []
			for (let i = 0.2; i <= 2.4; i += 0.2) {
				this.all_value.push(`${parseInt((i * this.value.box_size).toFixed(1))} px`)
			}
		},
		validateInput() {
			const rawValue = parseInt(this.userInput)
			if (isNaN(rawValue) || rawValue < 0 || rawValue > this.max_value) {
				this.inputError = this.langs["box_size_error_value"]
			} else {
				this.inputError = ''
			}
		},
		applyInputValue() {
			const rawValue = parseInt(this.userInput)
			if (!isNaN(rawValue) && rawValue >= 0 && rawValue <= this.max_value) {
				this.$emit('update:value', { ...this.value, box_size: rawValue })
				this.inputError = ''
			} else {
				this.inputError = this.langs["box_size_error_value"]
			}
		},
		openOffCanvas() {
			document.body.style.overflow = 'hidden'
		},
		closeOffCanvas() {
			document.body.style.overflow = ''
		},
	},
	created() {
		this.initializeAllValue()
	},
	watch: {
		'value.box_size': function (newVal) {
			this.userInput = `${newVal}px`
		},
	},
}
</script>
