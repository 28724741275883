<template>
  <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      ref="my-modal"
      id="offcanvasRightcontrast"
      aria-labelledby="offcanvasRightLabelcontrast">
    <div class="offcanvas-header">
      <h2 class="offcanvas-title text-center" id="offcanvasRightLabel">
        {{ langs["title_contrast"] }}
      </h2>
      <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"

      ></button>
    </div>
    <div class="offcanvas-body text-center">
      <img
          src="../../assets/explanation/contrast.png"
          alt="an example showing what is version"
          width="300px"/>
      <br/>
      {{ langs["contrast_explain"] }}
      <br/>
      <br/>
      <p class="fs-4 text-center">{{ langs["contrast_btn_describe"] }}
      </p>
      <div class="buttons-container">
        <button
            v-for="n in all_value"
            :key="n"
            @click="changeButtonContrast(n)"
            :class="{
						'btn-primary': value.contrast === parseRawValue(n),
						'btn-secondary': value.contrast !== parseRawValue(n),
					}"
            class="btn m-1">
          {{ n }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    langs: Object,
    value: Object
  },
  methods: {
    parseRawValue(val) {
      console.log
      return parseFloat(val)
    },
    changeButtonContrast(n) {
      this.$emit('update:value', {...this.value, contrast: this.parseRawValue(n)})
    },
    initializeAllValue() {
      this.all_value = []
      for (let i = -2; i <= 2; i += 0.2) {
        this.all_value.push(`${Math.ceil(i * 10) / 10} px`)
      }
    }
  },
  created() {
    this.initializeAllValue()
  }
}
</script>
