import {createApp} from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/main.css';

// import eruda from 'eruda';
// eruda.init();


async function initApp() {
    try {
        const app = createApp(App);
        app.config.globalProperties.TMA = window.Telegram.WebApp;
        app.mount('#app');
    } catch (error) {
        console.error('Failed to initialize app:', error);
    }
}

initApp();
