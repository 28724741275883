<template>
	<div
		class="offcanvas offcanvas-end"
		tabindex="-1"
		id="offcanvasRightversion"
		aria-labelledby="offcanvasRightLabelversion">
		<div class="offcanvas-header">
			<h2 class="offcanvas-title text-center" id="offcanvasRightLabel">
        {{ langs["title_version"] }}
			</h2>
			<button
				type="button"
				class="btn-close"
				data-bs-dismiss="offcanvas"
				aria-label="Close"></button>
		</div>
		<div class="offcanvas-body text-center">
			<img
				src="../../assets/explanation/version.png"
				alt="an example showing what is version"
				width="300px" />
			<br />
        {{ langs["version_explain"] }}
			<br />
			<br />

			<p class="fs-4 text-center">
        {{ langs["version_btn_describe"] }}</p>
			<div class="buttons-container">
				<button
					v-for="n in all_value"
					:key="n"
					@click="changeButtonColor(n)"
					:class="{
						'btn-primary': value.version === parseRawValue(n),
						'btn-secondary': value.version !== parseRawValue(n),
					}"
					class="btn m-1">
					{{ n }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: Object,
    langs: Object
	},
	methods: {
		parseRawValue(val) {
			return parseInt(val.split(' ')[0])
		},
		changeButtonColor(n) {
			this.$emit('update:value', { ...this.value, version: this.parseRawValue(n) })
		},
		initializeAllValue() {
			this.all_value = []
			for (let i = 1; i <= 40; i ++) {
				this.all_value.push(`${i} px`)
			}
		}
	},
	created() {
		this.initializeAllValue()
	}
}
</script>

