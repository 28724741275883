/* eslint-disable */

const ru_lang = {
    "main_title": "Настройки QR-кода |beta|",
    "btn_save": "Сохранить",
    "btn_factory_settings": "К заводским настройкам",
    "title_colour": "Цвет",
    "title_error_correction": "Коррекция ошибок",
    "title_border": "Размер рамки",
    "title_box_size": "Размер пикселей",
    "title_crop": "Обрезка изображения",
    "title_version": "Версия QR-кода",
    "title_contrast": "Контраст",
    "title_brightness": "Яркость",
    "title_speed_blocks": "Скорость анимации",
    "yes": "Да",
    "no": "Нет",
    "btn_copy": "Копировать",
    "btn_save_change": "Сохранить изменения",

    "box_size_explain": "Пиксель — это минимальная единица изображения в QR-коде (точка). Влияет только на размер QR-кода.",
    "box_size_main_title": "Размер пикселей",
    "box_size_btn_describe": "Изменить размер пикселей",
    "box_size_error_value": "❌ Ошибка. Введите значение от 1 до 1000",
    "error_correction_explain": "Определите устойчивость QR-кода к повреждениям. Чем выше процент, тем выше вероятность что QR-код будет читаться при повреждениях.",
    "error_correction_main_title": "Коррекция ошибок",
    "error_correction_btn_describe": "Изменить уровень коррекции ошибок",
    "crop_explain": "Подгонять размер изображения под квадрат?",
    "pixel_color_title": "Цвет пикселей",
    "background_color_title": "Цвет фона",
    "warning_color": "⚠️ Используйте более контрастные цвета!",
    "version_explain": "Определите размер и сложность QR-кода. Чем больше размер, тем лучше видно изображение, но могут возникнуть проблемы при сканировании",
    "version_btn_describe": "Выберите версию",
    "contrast_explain": "Контрастность выделяет элементы QR-кода на слишком светлом или темном изображении",
    "contrast_btn_describe": "Выберите уровень контраста",
    "brightness_explain": "Яркость помогает выделить пиксели QR-кода на слишком светлом или темном изображении",
    "brightness_btn_describe": "Выберите уровень яркости",
    "speed_blocks_explain": "Чем больше скорость падения блоков, тем раньше завершится анимация тетрис",
    "speed_blocks_btn_describe": "Выберите скорость анимации",
    "border_explain": "Рамка - это пустое пространство вокруг QR-кода",
    "border_btn_describe": "Изменить ширину рамки",
    "border_error_value": "Ошибка: укажите значение от 0 до 1000."
};


const de_lang = {
    "main_title": "QR-Code Einstellungen |beta|",
    "btn_save": "Speichern",
    "btn_factory_settings": "Auf Werkeinstellungen zurücksetzen",
    "title_colour": "Farbe",
    "title_error_correction": "Fehlerkorrektur",
    "title_border": "Rahmengröße",
    "title_box_size": "Modulgröße",
    "title_crop": "Bild zuschneiden",
    "title_version": "QR-Code-Version",
    "title_contrast": "Kontrast",
    "title_brightness": "Helligkeit",
    "title_speed_blocks": "Animationsgeschwindigkeit",
    "yes": "Ja",
    "no": "Nein",
    "btn_copy": "Kopieren",
    "btn_save_change": "Änderungen speichern",

    "box_size_explain": "Ein Modul ist die kleinste Einheit eines QR-Codes (entspricht einem 'Pixel'). Es beeinflusst die Größe des QR-Codes.",
    "box_size_main_title": "Modulgröße",
    "box_size_btn_describe": "Modulgröße ändern",
    "box_size_error_value": "❌ Fehler. Bitte geben Sie einen Wert zwischen 1 und 1000 ein",

    "error_correction_explain": "Dieser Parameter bestimmt die Beständigkeit des QR-Codes gegen Beschädigungen. Ein höheres Niveau vergrößert den QR-Code. Blau markiert potenziell beschädigbare Bereiche, Rot kritische Bereiche.",
    "error_correction_main_title": "Fehlerkorrektur",
    "error_correction_btn_describe": "Fehlerkorrekturlevel ändern",

    "crop_explain": "Bildgröße an das quadratische Format anpassen.",

    "pixel_color_title": "Modulfarbe",
    "background_color_title": "Hintergrundfarbe",
    "warning_color": "⚠️ Verwenden Sie kontrastreiche Farben für eine bessere Lesbarkeit!",

    "version_explain": "Dieser Parameter bestimmt die Größe und Komplexität des QR-Codes. Eine größere Version verbessert die Sichtbarkeit, erschwert jedoch das Scannen.",
    "version_btn_describe": "Version auswählen",

    "contrast_explain": "Kontrast hilft, die Elemente des QR-Codes auf zu hellen oder zu dunklen Bildern hervorzuheben.",
    "contrast_btn_describe": "Kontrastlevel wählen",

    "brightness_explain": "Die Helligkeit hilft, die Module des QR-Codes auf zu hellen oder zu dunklen Bildern deutlich zu machen.",
    "brightness_btn_describe": "Helligkeitslevel wählen",

    "speed_blocks_explain": "Je höher die Fallgeschwindigkeit der Blöcke, desto schneller endet die Tetris-QR-Code Animation.",
    "speed_blocks_btn_describe": "Animationsgeschwindigkeit wählen",

    "border_explain": "Der Rahmen ist der leere Raum zwischen den Rändern des QR-Codes und den Bildgrenzen. Die Rahmengröße hängt von der Modulgröße ab.",
    "border_btn_describe": "Rahmenbreite ändern",
    "border_error_value": "Fehler: Bitte geben Sie einen Wert zwischen 0 und 1000 an."
};

const en_lang = {
    "main_title": "QR Code Settings |beta|",
    "btn_save": "Save",
    "btn_factory_settings": "Reset to Factory Settings",
    "title_colour": "Color",
    "title_error_correction": "Error Correction",
    "title_border": "Border Size",
    "title_box_size": "Module Size",
    "title_crop": "Crop Image",
    "title_version": "QR Code Version",
    "title_contrast": "Contrast",
    "title_brightness": "Brightness",
    "title_speed_blocks": "Animation Speed",
    "yes": "Yes",
    "no": "No",
    "btn_copy": "Copy",
    "btn_save_change": "Save Changes",

    "box_size_explain": "A module is the minimum image unit in a QR code (akin to a 'pixel'). It only affects the size of the QR code.",
    "box_size_main_title": "Module Size",
    "box_size_btn_describe": "Change Module Size",
    "box_size_error_value": "❌ Error. Please enter a value from 1 to 1000",

    "error_correction_explain": "This parameter determines the QR code's resistance to damage. A higher level increases the QR code size. Blue indicates potentially damageable areas, red indicates critical areas.",
    "error_correction_main_title": "Error Correction",
    "error_correction_btn_describe": "Change Error Correction Level",

    "crop_explain": "Adjust the image size to fit a square format.",

    "pixel_color_title": "Module Color",
    "background_color_title": "Background Color",
    "warning_color": "⚠️ Use more contrasting colors for better visibility!",

    "version_explain": "This parameter determines the size and complexity of the QR code. A larger version enhances visibility but makes scanning more challenging.",
    "version_btn_describe": "Select Version",

    "contrast_explain": "Contrast helps to highlight QR code elements on overly bright or dark images.",
    "contrast_btn_describe": "Choose Contrast Level",

    "brightness_explain": "Brightness helps to make the QR code modules stand out on overly bright or dark images.",
    "brightness_btn_describe": "Choose Brightness Level",

    "speed_blocks_explain": "The faster the blocks fall, the quicker the Tetris QR code animation ends.",
    "speed_blocks_btn_describe": "Select Animation Speed",

    "border_explain": "The border is the empty space between the edges of the QR code and the image boundaries. The border size depends on the module size.",
    "border_btn_describe": "Change Border Width",
    "border_error_value": "Error: Please specify a value between 0 and 1000."
};


let langs = {
    "ru": ru_lang,
    "en": en_lang,
    "de": de_lang
};


let old_user_settings = {
    colour: {
        color_bg: [255, 255, 255],
        color_pixels: [1, 1, 1]
    },
    box_size: 10,
    border: 4,
    error_correction: 'H',
    crop: true,
    version: 8,
    contrast: 1.0,
    brightness: 1.0,
    speed_blocks: 0.7,
};


async function fetchUserLanguage(userId) {
    const url = `https://bot-api.qr-code-bot.ru/users/${userId}`; // шаблонная строка должна быть в ``
    try {
        const response = await fetch(url);
        if (response.ok) {
            const data = await response.json();
            return {
                lang: data.lang,
                qr_parameters: {
                    colour: data.qr_parameters.colour,
                    box_size: data.qr_parameters.box_size,
                    border: data.qr_parameters.border,
                    error_correction: data.qr_parameters.error_correction,
                    crop: data.qr_parameters.crop,
                    version: data.qr_parameters.version,
                    contrast: data.qr_parameters.contrast,
                    brightness: data.qr_parameters.brightness,
                    speed_blocks: data.qr_parameters.speed_blocks,
                },
                have_premium: data.have_premium,
            };
        } else {
            console.error('Failed to fetch user language: ', response.status);
            throw new Error(`Failed to fetch user language: ${response.status}`);
        }
    } catch (error) {
        console.error('Error fetching or parsing data: ', error);
        throw error;
    }
}
let userInfo = {
    lang: langs["en"],
    old_user_settings: old_user_settings,
    have_premium: true,
};

async function loadUserInfo(userId) {
    let info = null;

    try {
        info = await fetchUserLanguage(userId);
    } catch (error) {
        console.error('Error loading user info: ', error);
        throw error;
    }

    if (info) {
        return {
            lang: langs[info.lang] || langs["en"],
            old_user_settings: info.qr_parameters,
            have_premium: Boolean(info.have_premium),
        };
    }

    return {
        lang: langs["en"],
        old_user_settings: old_user_settings,
        have_premium: false,
    };
}


export {loadUserInfo};



