<template>
	<div
		class="offcanvas offcanvas-end"
		tabindex="-1"
		id="offcanvasRighterror_correction"
		aria-labelledby="offcanvasRightLabelerror_correction">
		<div class="offcanvas-header">
			<h5 class="offcanvas-title" id="offcanvasRightLabel">
        {{ langs["error_correction_main_title"]}}
			</h5>
			<button
				type="button"
				class="btn-close"
				data-bs-dismiss="offcanvas"
				aria-label="Close"></button>
		</div>
		<div class="info-blok">
			<div class="image-container">
				<div class="offcanvas-body">
					<img
						src="../../assets/explanation/error_correction.png"
						alt="Еxample showing what is error correction" />
				</div>
			</div>
			{{ langs["error_correction_explain"]}}
			<br />
			<br />
			<p class="fs-3 text-center">
        {{ langs["error_correction_btn_describe"]}}</p>
			<div class="buttons-container">
				<button
					v-for="n in all_value"
					:key="n"
					@click="changeButtonColor(n)"
					:class="{
						'btn-primary': value.error_correction === letterToPercentage(n),
						'btn-secondary': value.error_correction !== letterToPercentage(n),
					}"
					class="btn m-1">
					{{ n }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    langs: Object,
    value: Object
  },
	data() {
		return {
			all_value: ['30%', '25%', '15%', '7%'],
			errorCorrectionLevel: 1,
			showNotification: false,
			fadeIn: false,
			fadeOut: false,
			activeButton: null,
		}
	},
	methods: {
		changeButtonColor(n) {
			const newValue = this.letterToPercentage(n)
			this.$emit('update:value', { ...this.value, error_correction: newValue })
		},
		letterToPercentage(letter) {
			switch (letter) {
				case '30%':
					return 'H'
				case '25%':
					return 'Q'
				case '15%':
					return 'M'
				case '7%':
					return 'L'
				default:
					return 'H'
			}
		},
	},
}
</script>
