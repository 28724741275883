<template>
  <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasRightcolour"
      aria-labelledby="offcanvasRightLabelcolour">
    <div class="offcanvas-header">
      <h2 class="offcanvas-title text-center" id="offcanvasRightLabel">
        {{ langs["title_colour"] }}
      </h2>
      <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"></button>
    </div>

    <div class="offcanvas-body text-center">
      <ColorQRCode
          :colour="{
					color_bg: value.colour.color_bg,
					color_pixels: value.colour.color_pixels,
				}"
          :size="'256px'">
      </ColorQRCode>
      <div class="color-picker mt-3 text-center">
        <div>
          <div>
            <label for="bg-color mb-3">{{
                langs["background_color_title"]
              }}</label>
            <br/>
            <div ref="colorPicker"></div>
            <input
                type="text"
                class="form-control mt-2"
                v-model="displayColorBg"/>
            <button v-if="!isAndroid"
                    class="btn btn-primary mt-2"
                    @click="copyColor(displayColorBg)">
              {{ langs["btn_copy"] }}
              <i
                  :class="
									copied ? 'bi bi-clipboard-check' : 'bi bi-clipboard'
								"></i>
            </button>
          </div>
        </div>
        <div>
          <label for="pixel-color mb-3">
            {{ langs["pixel_color_title"] }}
          </label>
          <br/>
          <div ref="pixelColorPicker"></div>
          <input
              type="text"
              class="form-control mt-2"
              v-model="displayColorPixels"/>
          <button v-if="!isAndroid"
                  class="btn btn-primary mt-2"
                  @click="copyColor(displayColorPixels, true)">
            {{ langs["btn_copy"] }}
            <i
                :class="
								copiedPixels ? 'bi bi-clipboard-check' : 'bi bi-clipboard'
							"></i>
          </button>
        </div>
      </div>
      <div v-if="!isScannable" class="alert alert-warning mt-4">
        {{ langs["warning_color"] }}
      </div>
    </div>
  </div>
</template>

<script>
import ColorQRCode from '../color_QR_code.vue'
import iro from '@jaames/iro'


export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    langs: {
      type: Object,
      required: true,
    },
  },
  components: {
    ColorQRCode,
  },
  data() {
    return {
      color_bg: '#ffffff',
      color_pixels: '#000000',
      isScannable: true,
      displayColorBg: '',
      colorPickerInstance: null,
      copied: false,
      displayColorPixels: '',
      copiedPixels: false,
      isAndroid: false,
    }
  },
  methods: {

    parseColor(hex) {
      var r = parseInt(hex.substring(1, 3), 16)
      var g = parseInt(hex.substring(3, 5), 16)
      var b = parseInt(hex.substring(5, 7), 16)
      return [r, g, b]
    },
    canScanQRCode(color1, color2) {
      const getRelativeLuminance = rgb => {
        return rgb
            .map(c => {
              c /= 255
              return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)
            })
            .reduce(
                (acc, val, idx) => acc + val * [0.2126, 0.7152, 0.0722][idx],
                0
            )
      }
      const lum1 = getRelativeLuminance(color1)
      const lum2 = getRelativeLuminance(color2)
      const contrastRatio =
          (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05)

      return contrastRatio >= 4.5
    },
    checkScannability() {
      const colorBg = this.parseColor(this.color_bg)
      const colorPixels = this.parseColor(this.color_pixels)
      this.isScannable = this.canScanQRCode(colorBg, colorPixels)
    },
    convert_to_rgb(arr) {
      return `rgb(${arr[0]}, ${arr[1]}, ${arr[2]})`
    },
    copyColor(color, isPixel = false) {
      navigator.clipboard
          .writeText(color)
          .then(() => {
            if (isPixel) {
              this.copiedPixels = true
              setTimeout(() => {
                this.copiedPixels = false
              }, 2000)
            } else {
              this.copied = true
              setTimeout(() => {
                this.copied = false
              }, 2000)
            }
          })
          .catch(err => {
            console.error('Ошибка при копировании: ', err)
          })
    },
    rgbToHex(rgb) {
      const [r, g, b] = rgb.match(/\d+/g).map(Number)
      return (
          '#' +
          [r, g, b]
              .map(x => {
                const hex = x.toString(16)
                return hex.length === 1 ? '0' + hex : hex
              })
              .join('')
      )
    },
    validateRGB(rgb) {
      return rgb.every(value => value >= 0 && value <= 255)
    },
  },
  watch: {
    color_bg(newVal) {
      this.checkScannability()
      this.$emit('update:value', {
        ...this.value,
        colour: {...this.value.colour, color_bg: this.parseColor(newVal)},
      })
    },
    color_pixels(newVal) {
      this.checkScannability()
      const newColour = {
        ...this.value.colour,
        color_pixels: this.parseColor(newVal),
      }
      this.$emit('update:value', {...this.value, colour: newColour})
    },
    displayColorBg(newVal) {
      if (this.colorPickerInstance) {
        const hexColor = this.rgbToHex(newVal)
        if (this.validateRGB(this.parseColor(hexColor))) {
          this.colorPickerInstance.color.hexString = hexColor
          this.color_bg = hexColor
          this.checkScannability()
        } else {
          console.log('Неверный формат цвета')
        }
      }
    },
  },
  mounted() {
    this.isAndroid = /Android/i.test(navigator.userAgent);
    this.colorPickerInstance = new iro.ColorPicker(this.$refs.colorPicker, {
      width: 150,
      color: this.color_bg,
    })

    this.colorPickerInstance.on('color:change', color => {
      this.displayColorBg = this.convert_to_rgb(
          this.parseColor(color.hexString)
      )
    })

    const pixelColorPicker = new iro.ColorPicker(this.$refs.pixelColorPicker, {
      width: 150,
      color: this.color_pixels,
    })

    pixelColorPicker.on('color:change', color => {
      this.color_pixels = color.hexString
      this.displayColorPixels = this.convert_to_rgb(
          this.parseColor(color.hexString)
      )
      this.checkScannability()
    })
  },
}
</script>
