<template>
	<div
		class="offcanvas offcanvas-end"
		tabindex="-1"
		id="offcanvasRightborder"
		aria-labelledby="offcanvasRightLabelborder">
		<div class="offcanvas-header">
			<h5 class="offcanvas-title" id="offcanvasRightLabel">
				{{  langs["title_border"] }}
			</h5>
			<button
				type="button"
				class="btn-close"
				data-bs-dismiss="offcanvas"
				aria-label="Close"></button>
		</div>
		<div class="info-blok mb-5">
			<div class="image-container">
				<div class="offcanvas-body">
					<img
						src="../../assets/explanation/border.png"
						alt="an example showing what is border" />
				</div>
			</div>
			{{ langs["border_explain"] }}
			<br />
			<br />
			<p class="fs-4 text-center">{{ langs["border_btn_describe"] }}</p>
			<div class="buttons-container">
				<button
					v-for="n in all_value"
					:key="n"
					@click="changeButtonColor(n)"
					:class="{
						'btn-primary': value.border === parseRawValue(n),
						'btn-secondary': value.border !== parseRawValue(n),
					}"
					class="btn m-1">
					{{ n }}
				</button>
			</div>

			<div class="input-group mt-3 p-2">
				<input
					type="text"
					class="form-control"
					:placeholder="placeholder"
					v-model="userInput"
					@input="validateInput" />
				<button
					class="btn btn-outline-secondary"
					type="button"
					@click="applyInputValue">
					{{ langs["btn_save"] }}
				</button>
			</div>
			<div v-if="inputError" class="alert alert-danger mt-2">
				{{ inputError }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    langs: Object,
    value: Object
  },
	data() {
		return {
			all_value: [],
			errorCorrectionLevel: 1,
			showNotification: false,
			fadeIn: false,
			fadeOut: false,
			activeButton: null,
			userInput: ``,
			placeholder: `${this.value.border * this.value.box_size}px`,
			max_value: 999,
		}
	},
	methods: {
		changeButtonColor(n) {
			const newValue = this.parseRawValue(n)
			this.$emit('update:value', { ...this.value, border: newValue })
		},
		parseRawValue(val) {
			const numVal = parseInt(val.split(' ')[0])
			return numVal
		},
		initializeAllValue() {
			this.all_value = []
			for (let i = 0; i <= 2.2; i += 0.2) {
				this.all_value.push(
					`${parseInt(i * this.value.box_size)} m`
				)
			}
		},
		raw_value(val) {
			return parseInt(val) / this.value.box_size
		},
		validateInput() {
			const rawValue = parseInt(this.userInput)
			if (isNaN(rawValue) || rawValue < 0 || rawValue >= this.max_value) {
				this.inputError = this.langs["error_value"]
			} else {
				this.inputError = ''
			}
		},
		applyInputValue() {
			const rawValue = parseInt(this.userInput)
			if (!isNaN(rawValue) && rawValue >= 0 && rawValue <= this.max_value) {
				const newValue = rawValue / this.value.box_size
				this.$emit('update:value', { ...this.value, border: newValue })
				this.inputError = ''
			} else {
				this.inputError = this.langs["error_value"]
			}
		},
		openOffCanvas() {
			document.body.style.overflow = 'hidden'
		},
		closeOffCanvas() {
			document.body.style.overflow = ''
		},
	},
	created() {
		this.initializeAllValue()
	},
	watch: {
		'value.border': function (newVal) {
			console.log(newVal)
		},
		'value.box_size': function (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.initializeAllValue()
			}
		},
	},
}
</script>
