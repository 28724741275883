<template>
  <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasRightcrop"
      aria-labelledby="offcanvasRightLabelcrop">
    <div class="offcanvas-header">
      <h2 class="offcanvas-title text-center" id="offcanvasRightLabel">
        {{ langs["title_crop"] }}
      </h2>

      <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"></button>
    </div>
    <div class="offcanvas-body text-center">
      <img
          src="../../assets/explanation/crop.png"
          alt="an example showing what is crop"
          width="300px"/>
      <br/>
      {{ langs["crop_explain"] }}
      <br/>
      <br/>

      <button :class="getClass('yes')" class="btn m-2" @click="set_crop(true)">
        {{ langs["yes"] }}
      </button>

      <button class="btn m-2" :class="getClass('no')" @click="set_crop(false)">
        {{ langs["no"] }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    langs: Object,
    value: Object
  },
  components: {},
  methods: {
    set_crop(val) {
      this.$emit('update:value', {...this.value, crop: val})
    },
    getClass(type) {
      if (type == "yes" & this.value.crop === true) {
        return "btn-primary"
      } else if (type == "yes" & this.value.crop === false) {
        return "btn-secondary"
      } else if (type == "no" & this.value.crop === true) {
        return "btn-secondary"
      } else if (type == "no" & this.value.crop === false) {
        return "btn-primary"
      }
    }
  }
}
</script>
